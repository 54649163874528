<template lang="pug">
.edit-matrix__body
  .edit-matrix__title {{ 'ui.dropdown.select_skills' | translate }}
  v-radio-group.edit-matrix--list(v-if="loaded", v-model="radio")
    .edit-matrix--item
      v-radio(
        :id="0",
        :label="$t('pages.skill_gap.select_all_required_skills')"
      )
    .edit-matrix--item
      v-radio(:id="1", :label="$t('pages.skill_gap.select_unique_skills')")
      template(v-if="radio === 1")
        v-dropdown(:focusSwitchEL="focusSearch")
          v-input-search.input-search(
            :placeholder="$t('ui.inputs.search_skills')",
            type="text",
            :auto-focus="false",
            @focus="focusSearch = $event",
            v-model="searchSkills"
          )
          template(slot="content")
            v-card(elevation)
              v-scrollable(updateble, :min-height="20", :bottom-offset="15")
                template
                  v-list
                    v-list-item(
                      v-for="item in filterBadges",
                      :key="item.id",
                      @click="!selected.find((el) => el.id === item.id) ? onBadgeAdd(item) : onBadgeRemove(item)"
                    )
                      slot(name="icon")
                        badge(
                          :width="24",
                          :height="32",
                          :level="item.starsCount || 1",
                          :color="item.color"
                        )
                      v-list-item-content
                        v-list-item-title
                          span {{ item.displayName }}
                      v-list-item-actions
                        v-btn(
                          v-if="!selected.find((el) => el.id === item.id)",
                          icon,
                          success
                        )
                          icon-plus
                        v-btn(v-else, icon, danger)
                          icon-minus
                    v-list-empty(v-if="!filterBadges.length")
                      i.icon.icon--sm.icon--empty
                      span {{ 'ui.labels.list_is_empty' | translate }}

  .edit-matrix__baguettes(v-if="!loaded")
    v-skeleton(width="100%")
  v-scrollable.edit-matrix__content(
    v-else-if="selected.length && radio === 1",
    updateble,
    :min-height="20"
  )
    .edit-matrix__baguettes
      v-baguette(v-for="badge in selected", :key="badge.id")
        icon-badge
        span {{ badge.displayName }}
        v-btn(paddingless, text, @click="onBadgeRemove(badge)")
          icon-close.m-0
</template>

<script>
import api from '@/api'
import { mapGetters, mapMutations } from 'vuex'
import IconPlus from '@/components/svg/IconPlusAddToList.vue'
import IconMinus from '@/components/svg/icons/IconMinus.vue'
import IconClose from '@/components/svg/VInputSearch_CloseIcon'
import Badge from '@/components/svg/Badge.vue'
import IconBadge from '@//components/svg/skill-matrix/IconBadge.vue'

const RADIO_DATA = {
  UNIQUE_SKILLS: 1,
  PROFILE_SKILLS: 0
}
export default {
  name: 'SelectSkillsStep',
  components: { IconPlus, IconMinus, IconClose, Badge, IconBadge },

  props: {
    matrix: Object
  },

  data: () => ({
    badges: [],
    selected: [],
    loaded: false,
    searchSkills: '',
    radio: 0,
    focusSearch: false
  }),

  watch: {
    selected () {
      this.$emit('select', { badges: this.selected })
    },
    radio (value) {
      this.sendRadio(value)
    }
  },

  computed: {
    ...mapGetters('insightsMatrix', ['matrixList']),
    filterBadges () {
      return this.badges.filter((el) =>
        el.displayName.toUpperCase().includes(this.searchSkills.toUpperCase())
      )
    },
    matrixID () {
      return +this.$route.params.id || this.matrix.id
    },
    matrixItem () {
      return this.matrixList.find(
        (el) => el.id === this.matrixID
      )?.badgeImportModeId
    }
  },

  async mounted () {
    this.loadBadges()
    await this.loadSelectedBadges()
    this.defaultSelectdRadio()
    this.loaded = true
  },

  methods: {
    ...mapMutations('insightsMatrix', {
      updateMatrixItem: 'UPDATE_MATRIX_ITEM'
    }),

    async loadBadges () {
      const params = {
        noCategories: 1,
        sort: 'name',
        all: true
      }
      const { data } = await api.badges.list(params)
      this.badges = data
    },

    async loadSelectedBadges () {
      const { data } = await api.matrix.listBadges(this.matrix.id)
      this.selected = data
    },

    onBadgeAdd (item) {
      api.matrix.addBadges(this.matrix.id, { badgeIds: [item.id] })
      this.selected.push(item)
      if (this.matrixItem === 'UNIQUE_SKILLS' && item.typeId !== 'GRADATIONAL_SUBBADGE') {
        this.updateMatrixItem({ matrixID: this.matrix.id, status: 'add', key: 'badgesCount' })
      }
    },

    onBadgeRemove (item) {
      api.matrix.removeBadges(this.matrix.id, { badgeIds: [item.id] })
      const index = this.selected.findIndex((el) => el.id === item.id)
      this.selected.splice(index, 1)
      if (this.matrixItem === 'UNIQUE_SKILLS' && item.typeId !== 'GRADATIONAL_SUBBADGE') {
        this.updateMatrixItem({ matrixID: this.matrix.id, status: 'remove', key: 'badgesCount' })
      }
    },

    defaultSelectdRadio () {
      this.radio = RADIO_DATA[this.matrixItem] || 0
      this.sendRadio(this.radio)
    },
    sendRadio (value) {
      switch (value) {
        case 0:
          this.$emit('setBadgeImportModeId', { badgeImportModeId: 'PROFILE_SKILLS' })
          break
        case 1:
          this.$emit('setBadgeImportModeId', { badgeImportModeId: 'UNIQUE_SKILLS' })
          break
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.edit-matrix {
  &__title {
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
    color: $h2-title;
    margin-bottom: 24px;
  }
  &__content {
    flex: 1 0 auto;
    margin-top: 16px;
    max-height: 192px;
  }

  &--list {
    display: flex;
    flex-direction: column;
  }
  &--item {
    &:not(:last-child) {
      margin-bottom: 16px;
    }

    .v-dropdown {
      margin-top: 8px;
      padding-left: 40px;
      ::v-deep .v-dropdown__content {
        width: calc(100% - 40px);
        left: auto;
      }
      .v-card {
        padding: 8px 4px 8px 16px;
      }
      ::v-deep .v-list {
        padding-right: 20px;
      }
      ::v-deep .v-list__item {
        padding: 12px 8px;
        height: 48px;
        gap: 16px;
      }
      ::v-deep .v-list__item-title {
        font-size: 14px;
        line-height: 16px;
        font-weight: 400;
        color: $body-text;
      }
      ::v-deep .v-btn--icon {
        width: 24px;
        height: 24px;
      }
    }
  }
}
</style>
