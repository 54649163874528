<template lang="pug">
  .edit-matrix
    v-scrollable.matrix-grid__scroll(
          updateble
          :min-height="20")
      .matrix-grid
        .matrix-grid__left
          .matrix-grid__item(
            v-for="(column, index) in myMatrix.columns")
            v-baguette(
              v-for="(label, index) in column.labels"
              vertical
              :key="index"
              :color="column.color"
              :background-color="column.backgroundColor")
              v-icon {{ column.icon }}
              span
                | {{ label | translate }} {{ column.count ? `(${column.count})` : '' }}
        .matrix-grid__top
          .matrix-grid__item(
            v-for="(row, index) in myMatrix.rows")
            v-baguette(
              v-for="(label, index) in row.labels"
              :key="index"
              :color="row.color"
              :background-color="row.backgroundColor")
              v-icon {{ row.icon }}
              span
                | {{ label | translate }} {{ row.count ? `(${row.count})` : '' }}
          .matrix-grid__center(@click="swap")
            icon-swap
            span {{ 'pages.skill_gap.swap_rows_columns' | translate }}
</template>

<script>
import api from '@/api'
import { mapActions, mapMutations } from 'vuex'
import IconSwap from '@/components/svg/icons/IconSwap.vue'

export default {
  name: 'MatrixConfigurationStep',
  components: {
    IconSwap
  },

  props: {
    matrix: Object,
    myTemplateTitle: String,
    badgeImportModeId: String
  },

  data: () => ({
    isSwaped: false,
    badges: [],
    departments: [],
    employees: [],
    myMatrix: {
      rows: [],
      columns: []
    }
  }),

async mounted () {
    await this.loadSelectedBadges()
   const { data: dataEmployees } = await this.getEmployees(this.matrix.id)
   this.employees = dataEmployees
   const { data: dataDepartments } = await this.getDepartment(this.matrix.id)
   this.departments = dataDepartments
   this.init()
  },

  methods: {
    ...mapActions('insightsMatrix', [
      'updateMatrix'
    ]),

    ...mapActions('skillMatrix', [
      'getEmployees',
      'getDepartment'
    ]),

    ...mapMutations('insightsMatrix', {
      updateMatrixItem: 'UPDATE_MATRIX_ITEM'
    }),

    async loadSelectedBadges () {
      const { data } = await api.matrix.listBadges(this.matrix.id)
      this.badges = data
    },

    init () {
      this.myMatrix = {
        rows: [],
        columns: []
      }
      const labels = this.badges.map(item => item.displayName)
      this.myMatrix.rows.push({
        icon: 'badge',
        labels: labels,
        color: null
      })
      this.myMatrix.rows.push({ icon: 'badge', labels: ['ui.labels.badges'], color: '#29A687', backgroundColor: '#E3F6F1', count: this.badges.length })
      const labels2 = this.departments.map(item => item.name)
      this.myMatrix.columns.push({
        icon: 'profile',
        labels: labels2,
        color: null
      })
      this.myMatrix.columns.push({ icon: 'employees', labels: ['ui.labels.employees'], color: '#29A687', backgroundColor: '#E3F6F1', count: this.employees.length })
    },

   async onCreateMatrix () {
      const payload = {
        id: this.matrix.id,
        name: this.myTemplateTitle,
        isInverted: this.isSwaped,
        isRowColumnSwapped: this.matrix.isRowColumnSwapped,
        isViewSwapped: this.matrix.isViewSwapped,
        badgeImportModeId: this.badgeImportModeId
      }

      this.updateMatrix(payload)
      if (this.badgeImportModeId === 'PROFILE_SKILLS') {
          this.updateMatrixItem({ matrixID: this.matrix.id, status: null, key: 'badgesCount', count: 0 })
      } else {
        const { data } = await api.matrix.listBadges(this.matrix.id)
        const counts = data.filter(el => el.typeId !== 'GRADATIONAL_SUBBADGE').length
        this.updateMatrixItem({ matrixID: this.matrix.id, status: null, key: 'badgesCount', count: counts })
      }
    },

    swap () {
      const rows = this.myMatrix.rows
      this.myMatrix.rows = this.myMatrix.columns
      this.myMatrix.columns = rows
      this.isSwaped = !this.isSwaped
    }
  }
}
</script>

<style lang="scss" scoped>
  $min-size-column: 56px;
.edit-matrix{
  .matrix-grid {
    display: flex;
    border: 1px solid $color-line-dotted;
    min-height: 380px;
    &__scroll{
      max-height: 380px;
    }

    &__top {
      flex: 1;
      display: flex;
      flex-direction: column;
      .matrix-grid__item{
        box-sizing: border-box;
        min-height: $min-size-column;
        border-bottom: 1px solid $color-line-dotted;
      }

    }

    &__left {
      display: flex;
    .matrix-grid__item{
        box-sizing: border-box;
        min-width: $min-size-column;
        border-right: 1px solid  $color-line-dotted;
      }
    }

    &__center {
      display: flex;
      flex: 1;
      align-items: center;
      justify-content: center;
      background-color: $website-color;
      padding: 12px;
      box-sizing: border-box;

      svg{
        width: 16px;
        height: 16px;
        margin-right: 8px;
        color: $body-text;
      }

      span {
        font-size: 14px;
        line-height: 16px;
        font-weight: 400;
        color: $body-text;
        cursor: pointer;
        text-decoration: underline;
      }
    }

    &__item {
      padding: 12px;
      display: flex;
      align-items: flex-end;
      flex-wrap: wrap;
      gap: 8px;
        .v-baguette{
          gap: 8px;
          padding: 8px;
          svg{
            width: 16px;
            height: 16px;
            margin: 0;
          }
          span{
            font-size: 16px;
            line-height: 16px;
            font-weight: 400;
            color:$body-text;
          }
        }
    }
  }
}

</style>
