<template lang="pug">
v-card.edit-matrix
  v-card-title.edit-matrix--header
    edit-tilte(:title="myTemplateTitle", @setTitle="myTemplateTitle = $event")
    v-btn.edit-matrix--close(icon, success, @click="$emit('close')")
      icon-close(color="#A0AAC3")

  v-card-content
    v-stepper(ref="stepper", not-selectable, v-model="step")
      v-stepper-header
        v-stepper-step(step="0", :active-step="step")
          | {{ 'ui.dropdown.select_employees' | translate }}
        v-divider(dashed)
        v-stepper-step(step="1", :active-step="step")
          | {{ 'ui.dropdown.select_skills' | translate }}
        v-divider(dashed)
        v-stepper-step(step="2", :active-step="step")
          | {{ 'pages.skill_gap.matrix_configuration' | translate }}

      v-stepper-items
        v-stepper-content(step="0")
          select-employees-step(:matrix="matrix")

        v-stepper-content(step="1")
          select-skills-step(:matrix="matrix" @setBadgeImportModeId="setBadgeImportModeId")

        v-stepper-content(step="2")
          matrix-configuration-step(
            v-if="step === 2",
            ref="confStep",
            :my-template-title="myTemplateTitle",
            :matrix="matrix"
            :badgeImportModeId="badgeImportModeId",
          )

  v-card-actions
    v-btn.m-0(text, @click="onPrev")
      | {{ step > 0 ? 'ui.buttons.back' : 'ui.buttons.cancel' | translate }}
    v-btn.m-0(@click="onNext")
      | {{ step === 2 ? 'ui.buttons.save' : 'ui.buttons.next' | translate }}
</template>

<script>
import SelectEmployeesStep from './SelectEmployeesStep'
import SelectSkillsStep from './SelectSkillsStep'
import MatrixConfigurationStep from './MatrixConfigurationStep'
import IconClose from '@/components/svg/IconClose.vue'
import editTilte from '../editTitle.vue'

export default {
  name: 'CreateMatrixModal',
  props: {
    matrix: Object
  },

  components: {
    SelectEmployeesStep,
    SelectSkillsStep,
    MatrixConfigurationStep,
    editTilte,
    IconClose
  },

  data: () => ({
    step: 0,
    departments: [],
    locations: [],
    myTemplateTitle: '',
    badgeImportModeId: ''
  }),

  mounted () {
    this.myTemplateTitle = this.matrix.name
  },

  methods: {
    onPrev () {
      if (this.step > 0) {
        this.$refs.stepper.prevStep()
      } else {
        this.$emit('close')
      }
    },

    onNext () {
      if (this.step === 2) {
        this.$refs.confStep.onCreateMatrix()
        this.$emit('close')
        this.$emit('complete')
      } else {
        this.$refs.stepper.nextStep()
      }
    },
    setBadgeImportModeId (data) {
      this.badgeImportModeId = data.badgeImportModeId
    }
  }
}
</script>

<style lang="scss" scoped>
.edit-matrix {
  height: inherit;
  display: flex;
  flex-direction: column;
  &__body{
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  &--header {
    position: relative;
    display: flex;
    justify-content: space-between;
  }
  &--close{
    position: absolute;
    right: 24px;
    top: 24px;
    width: 24px;
    height: 24px;
    color: $body-text-2;
    svg {
      width: 12px;
      height: 12px;
    }
  }
    ::v-deep .v-dropdown {
    &.open {
      .v-inputserch--icon {
        color: $color-dark-green;
      }
    }
    .input-search {
      padding: 0;
      overflow: hidden;
      input {
        padding: 12px 8px 12px 16px;
        padding-right: 8px;
        font-size: 16px;
        line-height: 16px;
        font-weight: 400;
      }
    }
    .v-inputserch--icon {
      color: $body-text;
      padding: 12px 16px 12px 0;
    }
  }

  ::v-deep &__baguettes {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
  }
}
.v-stepper-header {
  flex: 0 0 auto;
  padding: 0;
  margin-bottom: 40px;
}

.v-card__content {
  flex: 1 0 auto;
  padding: 24px;
  .v-stepper {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  .v-stepper-items,
  .v-stepper-content {
    height: 100%;
  }
}
.v-card__actions {
  flex: 0 0 auto;
  gap: 16px;
}
.v-divider {
  align-self: start;
  margin-top: 12px;
  margin-bottom: 0;
}
::v-deep .v-baguette {
  gap: 8px;
  padding: 8px;
  svg {
    width: 16px;
    height: 16px;
    margin: 0;
  }
  span {
    font-size: 16px;
    line-height: 16px;
    font-weight: 400;
    margin: 0 !important;
    &::after {
      content: none;
    }
  }
}
::v-deep .v-radio {
  width: fit-content;
  gap: 16px;
  &__state-icon {
    width: 24px;
    height: 24px;
    margin: 0;
    background-color: $light-blue;
    border-color: $color-line-dotted;
    cursor: pointer;
    &--active {
      border-color: $color-dark-green;
      &::after {
        width: 15px;
        height: 15px;
      }
    }
  }
  &__label {
    font-size: 16px;
    line-height: 1;
    font-weight: 400;
    color: $h2-title;
  }
}
</style>
